import * as React from "react";
import Navbar from "../components/Navbar";
import "normalize.css";
import "../style/Fonts.css";
import "../style/layouts.css";
import Layout from "../components/layout";
import TitleCard from "../components/title";
import { useMediaQuery } from "react-responsive";
import { graphql } from "gatsby";
import Footer from "../components/footer"
import Container from "../components/textcontainer";
import PictureCard from "../components/picturecard";
import styled from "styled-components";
import * as constants from "../style/constants";
const StyledMain = styled.main`
  @media screen and (max-width: 413px) {
    padding-top: 100px;
    background-color: ${constants.white};
    font-family: ${constants.Dfont}, ${constants.Cfont};
  }
  @media (min-width: 414px) and (max-width: 767px) {
    padding-top: 130px;
    background-color: ${constants.white};
    font-family: ${constants.Dfont}, ${constants.Cfont};
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    padding-top: 120px;
    background-color: ${constants.white};
    font-family: ${constants.Dfont}, ${constants.Cfont};
  }
  @media (min-width: 1024px) and (max-width: 1499px) {
    background-color: ${constants.white};
    font-family: ${constants.Dfont}, ${constants.Cfont};
    min-height: 1400px;
  }
  @media (min-width: 1500px) {
    background-color: ${constants.white};
    font-family: ${constants.Dfont}, ${constants.Cfont};
    min-height: 1400px;
  }
`;
const TeamContainer = styled((props) => <Container {...props} />)`
  display: flex;
  justify-content: ${(props) => (props.center ? "center" : "space-around")};
  align-items: flex-start;
  flex-direction: row;
  max-width: 1200px;
  @media screen and (max-width: 1439px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: auto;
    max-width: 900px;
  }
  @media screen and (min-width: 1440px) {
    flex-direction: row;
    justify-content: space-around;
    padding-top: 100px;
    align-items: flex-start;
    margin: auto;
    max-width: 2300px;
  }
`;
const TeamCard = styled((props) => <PictureCard {...props} />)`
  font-family: ${(props) =>
    props.isenglish ? constants.Dfont : constants.Cfont};
  @media screen and (max-width: 374px) {
    max-width: 310px;
    display: grid;
    align-items: start;
    justify-items: center;
    grid-template-rows: 310px auto auto;
    margin-bottom: 20px;
  }
  @media screen and (min-width: 375px) and (max-width: 767px) {
    max-width: 360px;
    display: grid;
    align-items: start;
    justify-items: center;
    grid-template-rows: 350px auto auto;
    margin-bottom: 20px;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    max-width: 550px;
    display: grid;
    align-items: start;
    justify-items: center;
    grid-template-rows: 550px auto auto;
    margin-bottom: 20px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    max-width: 550px;
    display: grid;
    align-items: start;
    justify-items: center;
    grid-template-rows: 550px auto auto;
    margin-bottom: 50px;
  }
  @media screen and (min-width: 1440px) {
    max-width: 460px;
    display: grid;
    align-items: start;
    justify-items: center;
    grid-template-rows: 460px auto auto;
    margin-bottom: 20px;
    span{
      font-size:16px;
      line-height:28px;
    }
  }
`;
const IndexPage = ({data}) => {
  const isSmallPhone = useMediaQuery({ query: "(max-width: 424px)" });
  const isPhone = useMediaQuery({
    query: "(min-width: 425px) and (max-width: 767px)",
  });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1023px)",
  });
  const isLaptop = useMediaQuery({
    query: "(min-width: 1024px) and (max-width: 1439px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1440px)" });
  const showNav = useMediaQuery({ query: "(min-width:1024px)" });
  return (
    <Layout responsive={isSmallPhone || isPhone || isTablet ? "phone" : ""}>
      <StyledMain>
        <title>Our Team</title>
        {showNav && <Navbar />}
        {(isLaptop || isBigScreen) &&<div style={{ marginTop: "3rem" }}></div>}
        <TitleCard
          style={{
            color: constants.black,
            fontSize: (!isLaptop && !isBigScreen && "35px") || "",
            marginLeft: (!isSmallPhone  && "30px") || "",
          }}
        >
          优译的服务团队
        </TitleCard>
        <div style={{ marginTop: "3rem" }}></div>
        <TeamContainer responsive={"column"}>
          <TeamCard
            src={data.Image1.childImageSharp.fluid}
            isenglish={"yes"}
            title={`创办人/Michael Tam`}
            width={
              (isSmallPhone && 300) ||
              (isPhone && 350) ||
              (isTablet && 540) ||
              (isLaptop && 540) ||
              (isBigScreen && 450)
            }
            height={
              (isSmallPhone && 300) ||
              (isPhone && 350) ||
              (isTablet && 540) ||
              (isLaptop && 540) ||
              (isBigScreen && 450)
            }
          >
            从小时候开始，Michael就对游戏充满热情。他热衷于探索不同语种的游戏，希望能通过游戏了解到更多元的文化历史。但他发现很多游戏在本土化的过程中会因为翻译原因丢失最有魅力的文化内涵，这也成为了他创办优译的原因。Michael希望把自己对游戏的热情以及对不同文化的理解带给更多玩家，让游戏不仅仅是游乐而是了解文化认识世界的过程。如今，Michael凭借对游戏的热情以及多年的金融管理经验，带领团队与跨国大企业建立合作关系，并推动多个游戏本土上市。
          </TeamCard>
          <TeamCard
            src={data.Image2.childImageSharp.fluid}
            isenglish={"yes"}
            title={"本地化主管/Andrew Song"}
            width={
              (isSmallPhone && 300) ||
              (isPhone && 350) ||
              (isTablet && 540) ||
              (isLaptop && 540) ||
              (isBigScreen && 450)
            }
            height={
              (isSmallPhone && 300) ||
              (isPhone && 350) ||
              (isTablet && 540) ||
              (isLaptop && 540) ||
              (isBigScreen && 450)
            }
          >
           游戏编程毕业的Andrew对游戏设计及游戏本土化有着敏锐的洞察力，能快速发现游戏流程以及翻译中出现的问题。大学毕业后Andrew最初在人工智能相关的企业进行翻译及编程工作，曾在Ubisoft担任LQA执行翻译管理官。Andrew在去年加入优译，利用他对游戏和编程的深度理解，总能为客户带来不一样的解决方案。 
          </TeamCard>
          <TeamCard
            src={data.Image3.childImageSharp.fluid}
            isenglish={"yes"}
            title={"本地化专员/James Wu"}
            width={
              (isSmallPhone && 300) ||
              (isPhone && 350) ||
              (isTablet && 540) ||
              (isLaptop && 540) ||
              (isBigScreen && 450)
            }
            height={
              (isSmallPhone && 300) ||
              (isPhone && 350) ||
              (isTablet && 540) ||
              (isLaptop && 540) ||
              (isBigScreen && 450)
            }
          >
            Jame Wu 担任本地化总监一职，负责领导与支持 UE
            的翻译人才，帮助他们达到不一样的关键绩效指标。
            在加入UE之前，James已经累积了多年的翻译行业经验，作为多年的自由译者
            James 有帮助过腾讯，Ubisoft和其他大型企业达到他们的翻译目标
          </TeamCard>
        </TeamContainer>
        <div style={{ paddingBottom: "2rem" }}></div>
      </StyledMain>
      <Footer />
    </Layout>
  );
};

export default IndexPage;
export const query = graphql`
  query{
    Image1: file(relativePath: {eq: "Mike_tam.jpg"}) {
    childImageSharp {
      fluid{
        ...GatsbyImageSharpFluid
      }
    }
  }
    Image3: file(relativePath: {eq: "james.png"}) {
    childImageSharp {
      fluid{
        ...GatsbyImageSharpFluid
      }
    }
  }
    Image2: file(relativePath: {eq: "andy.jpg"}) {
    childImageSharp {
      fluid{
        ...GatsbyImageSharpFluid
      }
    }
  }
}`;